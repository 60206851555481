import {
  Button,
  Grid,
  Typography,
  Box,
  Tooltip,
  IconButton,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { Form, useFormikContext, Formik } from "formik";
import TextInput from "../../../InputComponents/TextInput";
import Toggle from "../../../InputComponents/Toggle";
import { useTypedSelector } from "../../../../../../hooks/useTypedSelector";
import DateComp from "../../../InputComponents/DateComp";
import moment from "moment";
import Radio from "../../../InputComponents/Radio";
import RadioButtonGroup from "../../../InputComponents/RadioButtonGroup";
import ToggleButtonGroup from "../../../InputComponents/ToggleButtonGroups";
import Select from "../../../InputComponents/Select";
import {
  claim_reason,
  insuranceCompanyNames,
  reasonOptions,
} from "../options/care";
import { continuous_coverage_year_options } from "../options/star";
import { Add, Delete } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setProposalData } from "../../../../../../modules/proposal.slice";
import {
  useUploadPortingFile,
  useGetProposalDetailsQuery,
  useUpdateProposalFormData,
} from "../../../../../../services/Proposal.service";
import { Close } from "@mui/icons-material";
import { allowOnlyNumbers } from "../../../../../../utils/inputUtils";
import { nivaReasonOptions, policyType } from "../options/niva";
import PortablityToggle from "../../../InputComponents/PortablityToggle";


const PortabilityNiva = () => {
  const { values, submitForm, errors, setFieldValue } = useFormikContext();
  const { proposalData } = useTypedSelector((state) => state.proposal);
  console.log(errors, values);
  const { upload, isUploadLoading } = useUploadPortingFile();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { saveForm } = useUpdateProposalFormData(() => {});
  const { isPortability } = useGetProposalDetailsQuery();

  const intialValues = {
    ...(proposalData?.portability_details || {}),
    // For Future Use
    // ...proposalData?.ckyc_details || {},
    app_url: proposalData?.portability_details?.app_url || "",
  };

  const handleSubmit = async (values) => {
    console.log(values, "");
    try {
      const submissionData = {
        portability_details: {
          ...values,
        },
      };
      await saveForm(submissionData);
      dispatch(setProposalData(submissionData));

      if (isPortability) {
        navigate({
          pathname: "/proposal-summary",
          search: window.location.search,
        });
      }
    } catch (error) {
      console.error("Error during form submission:", error);
    }
  };

  return (
    <>
      <Formik initialValues={intialValues} onSubmit={handleSubmit}>
        {({ submitForm, values, setFieldValue }) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <h3>Give us the details of your current policy</h3>
              </Grid>
              <Grid item md={6} xs={12}>
                <DateComp
                  label={"Policy Expiry Date"}
                  name="policy_expiry_date"
                />
              </Grid>
              <Grid item md={6} xs={12}></Grid>
              {values?.policy_expiry_date && (
                <>
                  <Grid item md={6} xs={12}>
                    <Select
                      name="portability_reason"
                      label={
                        "Why do you want to port from your existing policy?"
                      }
                      options={nivaReasonOptions}
                      placeholder={"Reason For Porting"}
                    />
                  </Grid>
                  {values?.portability_reason === "Others" && (
                    <>
                      <Grid item md={6} xs={12}>
                        <TextInput
                          name="portability_reason_others"
                          label={"Please Enter Other Reasons"}
                          options={nivaReasonOptions}
                          placeholder={"Other Reasons"}
                        />
                      </Grid>
                    </>
                  )}
                  <Grid item xs={12}>
                    <RadioButtonGroup
                      label={
                        <span>
                          Is this a Retail or a Group Policy?
                          <br></br>
                          <i>
                            "Group health policy covers a group of people,
                            belonging to the same organisation or employer while
                            retail health insurance is purchased individually by
                            consumers."
                          </i>
                        </span>
                      }
                      name="policy"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Radio
                      label="Do you want the cumulative bonus to be converted to an enhanced sum Insured?"
                      name="convert_ci_to_si"
                      touched
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <b>Existing Insurer Details</b>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Select
                      options={insuranceCompanyNames}
                      touched
                      name={`previous_insurer`}
                      label="Previous Insurer Name"
                      placeholder="Select Insurer"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextInput
                      name={`previous_product_name`}
                      touched
                      label="Product Name"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextInput
                      name={`previous_policy_number`}
                      touched
                      label="Previous Policy Number"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Select
                      options={[
                        {
                          OPTION_KEY: "1",
                          OPTION_VAL: "1 year",
                        },
                        {
                          OPTION_KEY: "2",
                          OPTION_VAL: "2 year",
                        },
                        {
                          OPTION_KEY: "3",
                          OPTION_VAL: "3 year",
                        },
                      ]}
                      touched
                      name={`policy_period`}
                      label="Policy Tenure"
                      placeholder="Select Policy Tenure"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Select
                      options={policyType}
                      touched
                      name={`policy_type`}
                      label="Policy Type"
                      placeholder="Select Policy Type"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextInput
                      name="addons"
                      touched
                      label="Addons (if any)"
                      onBlur={() => {
                        if (values.addons === "") {
                          setFieldValue("addons", "NA");
                        }
                      }}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    {values.app_url ? (
                      <Box display="flex" flexDirection="column">
                        <Typography
                          fontSize="16px"
                          fontWeight="500"
                          marginBottom="8px"
                        >
                          View Insurance Document
                        </Typography>
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                          padding="1px"
                          border="1px solid #ccc"
                          borderRadius="4px"
                        >
                          <Box
                            flexGrow={1}
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            padding="4px"
                          >
                            <a
                              href={values.app_url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              View Insurance Document
                            </a>
                          </Box>
                          <Tooltip title="Clear">
                            <IconButton
                              onClick={() => setFieldValue(`app_url`, "")}
                              aria-label="clear"
                            >
                              <Close />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </Box>
                    ) : (
                      <TextInput
                        type="file"
                        name={`app_url`}
                        label="Upload Insurance Document"
                        // required = {true}
                        onChange={async (e) => {
                          const file = e.target.files[0];
                          if (file) {
                            const formData = new FormData();
                            formData.append("file", file);
                            try {
                              const response = await upload(formData);
                              const uploadedUrl = response?.data?.app_url;
                              if (uploadedUrl) {
                                setFieldValue(`app_url`, uploadedUrl);
                              }
                            } catch (error) {
                              console.error("File upload failed:", error);
                            }
                          }
                        }}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextInput
                      name={`sum_insured`}
                      touched
                      label="Sum Insured"
                      onInput={allowOnlyNumbers}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Radio
                      name="is_extended_policy"
                      label="Have you extended policy for a short term basis?"
                    />
                  </Grid>
                  {values.is_extended_policy === "Y" && (
                    <>
                      <Grid item md={6} xs={12}>
                        <DateComp
                          label={"Short Term End Date"}
                          name="short_term_date"
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextInput
                          name={`policy_remark`}
                          touched
                          label="Policy Remarks"
                        />
                      </Grid>
                    </>
                  )}
                  <Grid item xs={12}>
                    <Radio
                      name="is_maternity_coverage"
                      label="Maternity Coverage"
                    />
                  </Grid>
                  {values.is_maternity_coverage === "Y" && (
                    <>
                    <Grid item xs={12} md={6}>
                              <TextInput
                                label={"Maternity Benefit Limit"}
                                name={`maternity_limit`}
                              />
                    </Grid>
                                        <Grid item xs={12}md={6}>
                                        <TextInput
                                          label={"Maternity Waiting Period"}
                                          name={`maternity_waiting_period`}
                                        />
                              </Grid>
                              </>
                  )}
                  <Grid item xs={12}>
                    <Radio
                      name="is_longer_exclusion"
                      label="Whether the PED exclusions/time bound exclusions have longer exclusion period then the existing policy. "
                    />
                  </Grid>
                  {values.is_longer_exclusion === "Y" && (
                    <Grid item xs={12} mt={2}>
                      <Select
                        name="difference_in_waiting_period"
                        label="I am aware that the waiting period for the following disease(s)/treatment(s) is more than the previous policy terms.I hereby agree to observe the additional waiting period for the following disease(s)/treatment(s)."
                        options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(
                          (_item) => ({
                            OPTION_KEY: _item,
                            OPTION_VAL: `${_item} year`,
                          })
                        )}
                        placeholder="Select Difference in Waiting Period"
                      />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <PortablityToggle
                      name="members_covered_in_existing"
                      label="Which members are covered in existing policy?"
                    />
                  </Grid>
                  {values.members_covered_in_existing &&
                    values.members_covered_in_existing.members?.map((item) => (
                      <>
                        <Grid item xs={12}>
                          <Select
                            name={`member_data.${item}.continous_coverage_year`}
                            touched
                            options={continuous_coverage_year_options}
                            placeholder="Select a year"
                            label="For how many years the member have been renewing their health insurance without any break?"
                          />
                        </Grid>
                        {[
                          ...Array(
                            values.member_data?.[item]?.continous_coverage_year
                          ),
                        ]?.map((_, coverage_count) => (
                          <>
                            <Grid item xs={12} md={6}>
                              <TextInput
                                label={`Sum Insured in Year ${
                                  coverage_count + 1
                                } (${
                                  new Date().getFullYear() - coverage_count
                                }-${
                                  new Date().getFullYear() - coverage_count - 1
                                })`}
                                name={`member_data.${item}.prev_sum_insured.${coverage_count}`}
                              />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <TextInput
                                label={"Cumulative Bonus"}
                                name={`member_data.${item}.cumulative_bonus`}
                              />
                            </Grid>
                          </>
                        ))}
                        <Grid item xs={12} md={6}>
                          <TextInput
                            name={`member_data.${item}.ped`}
                            label="PED Declared"
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Radio
                            label="Have you made any claim(s) in the previous policies?"
                            touched
                            name={`member_data.${item}.is_claim_made`}
                            handleChange={(val) => {
                              if (val === "Y") {
                                setFieldValue(
                                  `member_data.${item}.claim_details`,
                                  [
                                    {
                                      claim_amount: "",
                                      claim_number: "",
                                      claim_remarks: "",
                                      claim_rejected: "",
                                      occurence_date: "",
                                      hospital_name: "",
                                      rejected_reason: "",
                                      illness_nature: "",
                                      claim_paid: "",
                                      oustanding_claim: "",
                                    },
                                  ]
                                );
                              } else
                                setFieldValue(
                                  `member_data.${item}.claim_details`,
                                  []
                                );
                            }}
                          />
                        </Grid>
                        {values.member_data?.[item] &&
                          values.member_data?.[item]?.is_claim_made === "Y" &&
                          values.member_data?.[item]?.claim_details.map(
                            (claim_detail, claim_idx) => (
                              <>
                                <Grid item xs={12}>
                                  <b>Please enter the claim details</b>
                                </Grid>
                                {/* <Grid item xs={12} md={6}>
                              <Select
                                name={`member_data.${item}.claim_details.${claim_idx}.reason_for_claim`}
                                label="Reason for claim"
                                options={claim_reason}
                              />
                            </Grid> */}
                                {/* <Grid item xs={12} md={6}>
                              <DateComp
                                name={`member_data.${item}.claim_details.${claim_idx}.visit_date`}
                                label="Visit Date"
                              />
                            </Grid> */}
                                <Grid item xs={12} md={6}>
                                  <TextInput
                                    name={`member_data.${item}.claim_details.${claim_idx}.claim_amount`}
                                    label="Claim Amount"
                                  />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                  {" "}
                                  <TextInput
                                    name={`member_data.${item}.claim_details.${claim_idx}.claim_number`}
                                    label="Claim Number"
                                  />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                  {" "}
                                  <TextInput
                                    name={`member_data.${item}.claim_details.${claim_idx}.claim_remarks`}
                                    label="Claim Remarks"
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <Radio
                                    label={"Claim Rejected"}
                                    name={`member_data.${item}.claim_details.${claim_idx}.claim_rejected`}
                                    touched
                                  />
                                </Grid>
                                <p>
                                  {values.member_data?.[item]?.claim_rejected}
                                </p>
                                {values?.member_data?.[item]?.claim_details[
                                  claim_idx
                                ].claim_rejected === "Y" ? (
                                  <>
                                    <Grid item xs={12} md={6}>
                                      <DateComp
                                        name={`member_data.${item}.claim_details.${claim_idx}.occurence_date`}
                                        label="Occurence Date"
                                      />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                      <TextInput
                                        name={`member_data.${item}.claim_details.${claim_idx}.hospital_name`}
                                        label="Hospital Name"
                                      />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                      <TextInput
                                        name={`member_data.${item}.claim_details.${claim_idx}.rejected_reason`}
                                        label="Rejected Reason"
                                      />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                      <TextInput
                                        name={`member_data.${item}.claim_details.${claim_idx}.illness_nature`} // Ensure no space in the property name
                                        label="Illness Nature"
                                      />
                                    </Grid>
                                  </>
                                ) : (
                                  <>
                                    <Grid item xs={12} md={6}>
                                      <DateComp
                                        name={`member_data.${item}.claim_details.${claim_idx}.occurence_date`}
                                        label="Occurence Date"
                                      />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                      <TextInput
                                        name={`member_data.${item}.claim_details.${claim_idx}.hospital_name`}
                                        label="Hospital Name"
                                      />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                      <TextInput
                                        name={`member_data.${item}.claim_details.${claim_idx}.illness_nature`} // Ensure no space in the property name
                                        label="Illness Nature"
                                      />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                      <TextInput
                                        name={`member_data.${item}.claim_details.${claim_idx}.claim_paid`}
                                        label="Claim Paid"
                                      />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                      <TextInput
                                        name={`member_data.${item}.claim_details.${claim_idx}.oustanding_claim`} // Make sure this name matches your state definition
                                        label="Outstanding Claim"
                                      />
                                    </Grid>
                                  </>
                                )}

                                {values.member_data?.[item]?.claim_details
                                  ?.length > 1 && (
                                  <Grid
                                    item
                                    xs={12}
                                    display={"flex"}
                                    justifyContent={"center"}
                                  >
                                    <Button
                                      color="error"
                                      variant="outlined"
                                      startIcon={<Delete />}
                                      onClick={() => {
                                        setFieldValue(
                                          `member_data.${item}.claim_details`,
                                          values.member_data?.[
                                            item
                                          ]?.claim_details.filter(
                                            (_item, curr_idx) => {
                                              return curr_idx !== claim_idx;
                                            }
                                          )
                                        );
                                      }}
                                    >
                                      Remove
                                    </Button>
                                  </Grid>
                                )}
                              </>
                            )
                          )}
                        {values.member_data?.[item]?.claim_details?.length <
                          3 &&
                          values.member_data?.[item]?.is_claim_made === "Y" && (
                            <Grid
                              item
                              xs={12}
                              display={"flex"}
                              justifyContent={"center"}
                            >
                              <Button
                                color="success"
                                variant="outlined"
                                startIcon={<Add />}
                                onClick={() => {
                                  setFieldValue(
                                    `member_data.${item}.claim_details`,
                                    [
                                      ...values.member_data?.[item]
                                        ?.claim_details,
                                      {
                                        claim_reason: "",
                                        visit_date: "",
                                        claim_amount: "",
                                        select_insurer: "",
                                        claim_number: "",
                                        payment_date: "",
                                      },
                                    ]
                                  );
                                }}
                              >
                                Add
                              </Button>
                            </Grid>
                          )}
                        <Grid container spacing={2}>
                          {values.member_data?.[item]?.is_claim_made === "N" ? (
                            <>
                              <Grid item xs={12}>
                                <FormControlLabel
                                  label={
                                    <span style={{ fontSize: "16px" }}>
                                      I propose to port my health insurance
                                      policy with Niva Bupa (formerly known as
                                      Max Bupa) Health Insurance Company
                                      Limited. I hereby declare that Niva Bupa
                                      (formerly known as Max Bupa) Health
                                      Insurance Company would not be held liable
                                      for lapse of coverage with my previous
                                      insurer and agree with the final decision
                                      of Niva Bupa (formerly known as Max Bupa)
                                      with respect to my proposal processed
                                      within 21 days from the login date. In
                                      case my application is declined by the
                                      company, I agree to cover any medical
                                      expenses (if any) required to underwrite
                                      my application.
                                    </span>
                                  }
                                  control={<Checkbox />}
                                  onChange={(e, checked) => {
                                    setFieldValue(
                                      "isAditionalWaiting",
                                      checked
                                    );
                                  }}
                                  checked={values.isAditionalWaiting}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <FormControlLabel
                                  label={
                                    <span style={{ fontSize: "16px" }}>
                                      I wish to apply for the Aspire Gold+
                                      (Direct) plan with your company under
                                      portability and declare that the rate of
                                      No Claim Bonus/Cumulative Bonus (NCB)
                                      stated by me is correct and that no claim
                                      has arisen in the expiring policy (copy of
                                      the policy and renewal notice enclosed).
                                      Niva Bupa (formerly known as Max Bupa)
                                      Health Insurance Company Limited will see
                                      confirmed of the above stated details from
                                      my previous insurer. Pending receipt of
                                      the necessary confirmation from the
                                      previous insurer, Niva Bupa (formerly
                                      known as Max Bupa) Health Insurer may
                                      issue the policy to me. Post insurance of
                                      the policy, If the information provided
                                      under this declaration is found be
                                      incorrect, the policy issued to be shall
                                      be cancelled ab-initio and all premium
                                      under the policy will stand forfeited.{" "}
                                    </span>
                                  }
                                  control={<Checkbox />}
                                  onChange={(e, checked) => {
                                    setFieldValue(
                                      "isAditionalWaiting",
                                      checked
                                    );
                                  }}
                                  checked={values.isAditionalWaiting}
                                />
                              </Grid>
                            </>
                          ) : (
                            <Grid item xs={12}>
                              <FormControlLabel
                                label={
                                  <span style={{ fontSize: "16px" }}>
                                    I propose to port my health insurance policy
                                    with Niva Bupa (formerly known as Max Bupa)
                                    Health Insurance Company Limited. I hereby
                                    declare that Niva Bupa (formerly known as
                                    Max Bupa) Health Insurance Company would not
                                    be held liable for lapse of coverage with my
                                    previous insurer and agree with the final
                                    decision of Niva Bupa (formerly known as Max
                                    Bupa) with respect to my proposal processed
                                    within 21 days from the login date. In case
                                    my application is declined by the company, I
                                    agree to cover any medical expenses (if any)
                                    required to underwrite my application.
                                  </span>
                                }
                                control={<Checkbox />}
                                onChange={(e, checked) => {
                                  setFieldValue("isAditionalWaiting", checked);
                                }}
                                checked={values.isAditionalWaiting}
                              />
                            </Grid>
                          )}
                        </Grid>
                      </>
                    ))}
                </>
              )}
              <Grid
                item
                xs={12}
                justifyContent="center"
                display={"flex"}
                my={1}
              >
                <Button
                  size="large"
                  variant="contained"
                  onClick={() => {
                    submitForm();
                  }}
                >
                  {"Proceed to Review & Pay"}
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default PortabilityNiva;
