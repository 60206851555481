export const nivaReasonOptions = [
  {
    OPTION_KEY: "PS",
    OPTION_VAL: "Poor Service experience",
  },
  {
    OPTION_KEY: "BC",
    OPTION_VAL: "Better comparision",
  },
  {
    OPTION_KEY: "BPF",
    OPTION_VAL: "Better product features",
  },
  {
    OPTION_KEY: "BI",
    OPTION_VAL: "Brand Image",
  },
  {
    OPTION_KEY: "OTHERS",
    OPTION_VAL: "Others",
  },
];
export const prev_policy_type_option = [
  {
    OPTION_KEY: "individual",
    OPTION_VAL: "individual",
  },
];
export const insuranceCompanyNames = [];
export const policyType = [
  {
    OPTION_KEY: "Individual",
    OPTION_VAL: "Individual",
  },
  {
    OPTION_KEY: "Floater",
    OPTION_VAL: "Floater",
  },
  {
    OPTION_KEY: "IF",
    OPTION_VAL: "Individual Floater",
  },
];

export const continuous_coverage_year_options = [
  {
    OPTION_KEY: 1,
    OPTION_VAL: "1 year",
  },
  {
    OPTION_KEY: 2,
    OPTION_VAL: "2 year",
  },
  {
    OPTION_KEY: 3,
    OPTION_VAL: "3 year",
  },
  {
    OPTION_KEY: 4,
    OPTION_VAL: "4 year",
  },
];

export const claim_reason = [
  {
    OPTION_KEY: "X1014",
    OPTION_VAL: "Calculus of urinary tract",
  },

  {
    OPTION_KEY: "X0669",
    OPTION_VAL: "Cardiac arrest",
  },
  {
    OPTION_KEY: "X0229",
    OPTION_VAL: "Malignant neoplasm of kidney",
  },
  {
    OPTION_KEY: "X0710",
    OPTION_VAL: "Acute pharyngitis",
  },
  {
    OPTION_KEY: "X1445",
    OPTION_VAL: "Other and unspecified injuries of Forearm",
  },
  {
    OPTION_KEY: "X1021",
    OPTION_VAL: "Cystitis",
  },
  {
    OPTION_KEY: "X1080",
    OPTION_VAL: "Other abortion",
  },
  {
    OPTION_KEY: "X0478",
    OPTION_VAL: "Specific developmental disorders of speech and language",
  },
  {
    OPTION_KEY: "X1500",
    OPTION_VAL: "Injury of unspecified of body",
  },
  {
    OPTION_KEY: "X0587",
    OPTION_VAL: "Other retinal disorders",
  },
  {
    OPTION_KEY: "X0101",
    OPTION_VAL: "Unspecified viral hepatitis",
  },
  {
    OPTION_KEY: "X0709",
    OPTION_VAL: "Acute sinusitis",
  },
  {
    OPTION_KEY: "X0784",
    OPTION_VAL: "Other diseases of lip and oral",
  },
  {
    OPTION_KEY: "X1408",
    OPTION_VAL: "Fracture of rib(s), sternum",
  },
  {
    OPTION_KEY: "X0677",
    OPTION_VAL: "Intracerebral haemorrhage",
  },
  {
    OPTION_KEY: "X0619",
    OPTION_VAL: "Other disorders of middle ear",
  },
  {
    OPTION_KEY: "X0253",
    OPTION_VAL: "Lymphoid leukaemia",
  },
  {
    OPTION_KEY: "X0724",
    OPTION_VAL: "Acute bronchitis",
  },
  {
    OPTION_KEY: "X0604",
    OPTION_VAL: "Other disorders of eye and adnexa",
  },
  {
    OPTION_KEY: "X1038",
    OPTION_VAL: "Other disorders of penis",
  },
  {
    OPTION_KEY: "X0014",
    OPTION_VAL: "Tuberculosis of other organs",
  },
  {
    OPTION_KEY: "X1333",
    OPTION_VAL: "Dizziness and giddiness",
  },
  {
    OPTION_KEY: "X1019",
    OPTION_VAL: "Other disorder of kidney",
  },
  {
    OPTION_KEY: "X1132",
    OPTION_VAL: "Normal Delivery",
  },
  {
    OPTION_KEY: "X1134",
    OPTION_VAL: "Caesarean Delivery",
  },
  {
    OPTION_KEY: "X0010",
    OPTION_VAL: "Diarrhoea and gastroenteritis",
  },

  {
    OPTION_KEY: "X1429",
    OPTION_VAL: "Dislocation sprain and strain",
  },
  {
    OPTION_KEY: "X1068",
    OPTION_VAL: "Fibroid Uterus",
  },
  {
    OPTION_KEY: "X0579",
    OPTION_VAL: "Pseudophakia",
  },
  {
    OPTION_KEY: "X1026",
    OPTION_VAL: "Urethral Stricture",
  },
  {
    OPTION_KEY: "X0801",
    OPTION_VAL: "Inguinal Hernia",
  },
  {
    OPTION_KEY: "X1612",
    OPTION_VAL: "Motor cycle rider injured to other and unspecified accident",
  },
  {
    OPTION_KEY: "X0309",
    OPTION_VAL: "Vitamin B12 deficiency anemia",
  },
  {
    OPTION_KEY: "X0267",
    OPTION_VAL: "Carcinoma in situ of other",
  },
  {
    OPTION_KEY: "X1202",
    OPTION_VAL: "Seizure or Respiratory distress in New born baby",
  },
  {
    OPTION_KEY: "X1341",
    OPTION_VAL: "Fever of unknown origin",
  },
  {
    OPTION_KEY: "X0808",
    OPTION_VAL: "Crohn's disease",
  },
  {
    OPTION_KEY: "X0284",
    OPTION_VAL: "Leiomyoma of uterus",
  },
  {
    OPTION_KEY: "X0794",
    OPTION_VAL: "Gastritis and duodenitis",
  },
  {
    OPTION_KEY: "X0293",
    OPTION_VAL: "Benign neoplasm of thyroid gland",
  },
  {
    OPTION_KEY: "X1448",
    OPTION_VAL: "Fracture at wrist and hand level",
  },
  {
    OPTION_KEY: "INA",
    OPTION_VAL: "Senile cataract",
  },
  {
    OPTION_KEY: "X0762",
    OPTION_VAL: "Pleural effusion, not elsewher",
  },
  {
    OPTION_KEY: "X0180",
    OPTION_VAL:
      "Malignant neoplasm of other and unspecified major salivary glands",
  },
  {
    OPTION_KEY: "X0360",
    OPTION_VAL: "Hypofunction and other disorders of pituitary gland",
  },
  {
    OPTION_KEY: "X0797",
    OPTION_VAL: "Acute appendicitis",
  },
  {
    OPTION_KEY: "X1468",
    OPTION_VAL: "Fracture of lower leg",
  },
  {
    OPTION_KEY: "X2073",
    OPTION_VAL: "Other abortion(D & C)",
  },
  {
    OPTION_KEY: "X1998",
    OPTION_VAL: "Care involving dialysis",
  },
  {
    OPTION_KEY: "X0604",
    OPTION_VAL: "Other disorders of eye",
  },
  {
    OPTION_KEY: "X0789",
    OPTION_VAL: "Disorders of oesophagus",
  },
  {
    OPTION_KEY: "X1069",
    OPTION_VAL: "Pain and other conditionsans and menstrual cycle",
  },
  {
    OPTION_KEY: "X1351",
    OPTION_VAL: "Oedema, not elsewhere classified",
  },
  {
    OPTION_KEY: "X0831",
    OPTION_VAL: "Cholelithiasis",
  },
  {
    OPTION_KEY: "X1045",
    OPTION_VAL: "Unspecified lump in breast",
  },
  {
    OPTION_KEY: "X0265",
    OPTION_VAL: "Carcinoma in situ of breast",
  },
  {
    OPTION_KEY: "X0679",
    OPTION_VAL: "Cerebral infarction",
  },
  {
    OPTION_KEY: "X1347",
    OPTION_VAL: "Convulsions, not elsewhere classified.",
  },
  {
    OPTION_KEY: "X0816",
    OPTION_VAL: "Fissure and fistula of anal and rectal regions",
  },
];
