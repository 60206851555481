import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Stack,
  Typography,
  Modal,
  TextField,
  IconButton,
  CircularProgress,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import HeartLoader from "../../../components/Loader/Loader";
import ShadowBox from "../../../components/ShadowBox/ShadowBox";
import PairData, { PairDataWithoutBox } from "../../../components/SummaryComponents/PairData";
import Title from "../../../components/SummaryComponents/Title";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import {
  useGetHdfcDocument,
  useGetProposalDetailsQuery,
  useProposalFinalSubmitMutation,
  useUpdateProposalMutation,
  useVerifyDocumentOtp,
} from "../../../services/Proposal.service";
import { commaSeparateNumber } from "../../../utils/priceUtils";
import { useGetRiders } from "../../../services/Quotes.service";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch } from "react-redux";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const ProductSummary: React.FC = () => {
  const { quote_data, product } = useGetProposalDetailsQuery();
  const { selectedRiders, selectedTenure, selected_members_rider } = useTypedSelector((state) => state.quote);
  const location = useLocation();
  const isProposal = location.pathname === "/proposal";
  const isProposalSummary = location.pathname.includes("proposal-summary");
  const { updateProposal } = useUpdateProposalMutation();
  const [portPolicy, setPortPolicy] = useState(false);
  const { refetchRiders, riders } = useGetRiders(selectedRiders);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [otp, setOtp] = useState("");
  const [otpError, setOtpError] = useState("");
  const dispatch = useDispatch();
  
  const { hdfcDocument: hdfcDocumentData, isLoading } = useGetHdfcDocument();
  console.log(isLoading,"isLoadingisLoading")
  const { submitProposal, isSubmitting, data } = useProposalFinalSubmitMutation();
  console.log(isSubmitting,"isSubmittingisSubmitting")
  const { handleVerifyOtp } = useVerifyDocumentOtp();
  const [submissionModalOpen, setSubmissionModalOpen] = useState(false);

  useEffect(() => {
    refetchRiders();
  }, [portPolicy, refetchRiders]);

  const mandatoryRiders = riders.filter((value) => value.IS_MANDETORY === "Y");

  const updatedSelectedRiders = Array.from(
    new Map(
      [
        ...selectedRiders,
        ...mandatoryRiders,
      ].map((rider) => [rider.ADD_ON_ID, rider])
    ).values()
  );

  const handlePortPolicyChange = (checked: boolean) => {
    setPortPolicy(checked);
    if (quote_data) {
      quote_data.ISPORTING = checked;
    }
  };

  const onProceed = () => {
    updateProposal({
      ADD_ONS: selectedRiders,
      TENURE: selectedTenure,
      TOTAL_PREMIUM: quote_data?.TOTAL_PREMIUM,
      NET_PREMIUM: quote_data?.TOTAL_PREMIUM,
      PORT_POLICY: portPolicy,
      selected_members: selected_members_rider,
    });
  };

  useEffect(() => {
    if (product?.INSURANCE_SLUG === "hdfc_ergo_optimasecure" && hdfcDocumentData && isProposalSummary && hdfcDocumentData.status === 200)  {
      if (hdfcDocumentData.status === 200 || hdfcDocumentData.status === 400) {
        setIsPopupOpen(true);
      }
        if ([200, 400, 500].includes(hdfcDocumentData.status)) {
        setSubmissionModalOpen(false);
      }
    }
  }, [hdfcDocumentData, product?.INSURANCE_SLUG, isProposalSummary]);
  

  const onProceedToPayment = async () => {
    await submitProposal();
    if(product?.INSURANCE_SLUG === "hdfc_ergo_optimasecure" ){
      setSubmissionModalOpen(true);
    }
  };

  const verifyOtp = async () => {
    setOtpError("");
    if (!otp) {
      setOtpError("Please enter OTP.");
      return;
    }
    try {
      const response = await handleVerifyOtp(otp);
      if (response?.data?.status === 400) {
        setOtpError(response?.data?.message);
        return;
      }
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };

  const additionalPremium = updatedSelectedRiders.reduce(
    (acc, curr: any) => acc + (curr?.IS_MANDETORY === "Y" ? 0 : +curr.premium),
    0
  );
  const mandatoryPremium = updatedSelectedRiders.reduce(
    (acc, curr: any) => acc + (curr?.IS_MANDETORY === "Y" ? +curr.premium : 0),
    0
  );

  return (
    <>
      <ShadowBox px={1} py={2} br sx={{ position: "sticky", top: 0 }}>
        <Stack spacing={1}>
          <Title title="Summary" />
          {/* {isSubmitting && <HeartLoader />} */}
          {isProposal && (
            <ShadowBox px={1}>
              <Box display="flex" gap="18px" alignItems={"center"}>
                <img src={product?.LOGO_PATH} alt="" height={"60px"} style={{ maxWidth: "120px" }} />
                <Typography fontSize={"15px"}>{product?.PRODUCT_NAME}</Typography>
              </Box>
            </ShadowBox>
          )}
          {!isProposalSummary && (
            <>
              <PairData label="Premium" value={commaSeparateNumber(Number(quote_data?.NET_PREMIUM))} />
              <PairData label="Cover" value={commaSeparateNumber(quote_data?.SUM_INSURED)} />
              <PairData label="Policy Period" value={`${selectedTenure} year`} />
            </>
          )}
          <Title title="Riders">
            {updatedSelectedRiders.length ? (
              updatedSelectedRiders.map((rider) => (
                <PairDataWithoutBox key={rider.ADD_ON_NAME} label={rider.ADD_ON_NAME} value={commaSeparateNumber(rider.premium)} />
              ))
            ) : (
              <Typography align="center" fontSize="14px" gutterBottom>
                No riders selected.
              </Typography>
            )}
          </Title>
          {!isProposal && !isProposalSummary && 
            ["star", "care", "niva_bupa", "hdfc_ergo_optimasecure", "aditya_birla_health"].includes(product?.INSURANCE_SLUG || "") && (
              <Title title="Port Existing Policy">
                <FormControlLabel
                  checked={portPolicy}
                  onChange={(e, checked) => handlePortPolicyChange(checked)}
                  sx={{ "& .MuiFormControlLabel-label": { fontSize: "14px" } }}
                  control={<Checkbox size="small" />}
                  label="I want to port my existing policy."
                />
              </Title>
            )}
          <PairData primary label="Total Premium" value={commaSeparateNumber(Number(quote_data?.NET_PREMIUM) + Number(additionalPremium))} />
          {!isProposal && !isProposalSummary && (
            <Button variant="contained" size="large" onClick={onProceed}>
              Proceed to Proposal
            </Button>
          )}
          {isProposalSummary && product?.INSURANCE_SLUG && (
            <Button variant="contained" size="large" onClick={onProceedToPayment}>
              Proceed to Payment
            </Button>
          )}
        </Stack>
      </ShadowBox>

      {product?.INSURANCE_SLUG === "hdfc_ergo_optimasecure" && (
        <>
 <Modal open={isPopupOpen} onClose={() => setIsPopupOpen(false)}>
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                bgcolor: 'background.paper',
                boxShadow: 24,
                width: { xs: '90%', sm: '80%', md: '60%', lg: '50%' },
                maxHeight: '80vh',
                overflowY: 'auto',
                p: { xs: 2, sm: 3, md: 4 },
              }}
            >
              <Box display="flex" justifyContent="center" alignItems="center" position="relative" mb={2}>
                <IconButton aria-label="close" onClick={() => setIsPopupOpen(false)} sx={{ position: 'absolute', right: 0, color: 'grey' }}>
                  <CloseIcon />
                </IconButton>

                <Typography variant="h5" textAlign="center">
                  Please find the CIS Document
                </Typography>
              </Box>

              <Typography variant="body2" sx={{ mb: 2 }}>
                Hi,
                <br />
                Thank you for choosing us to buy your health insurance policy. Find below the CIS document which gives you a glance at the key policy details in a summarized format.
                <br />
                <br />
                We encourage you to take a few moments to review the document. Understanding the details of your health insurance coverage ensures that you can make informed decisions about your healthcare needs.
                <br />
                <br />
                We have sent an OTP to your email id and mobile number. Please enter the OTP here to proceed further.
                <br />
                <br />
                {hdfcDocumentData?.cis_document ? (
                  <>
                    CIS Download Document:
                    <a
                      href={hdfcDocumentData.cis_document}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ marginLeft: '4px', color: 'blue', textDecoration: 'underline' }}
                    >
                      Download
                    </a>
                  </>
                ) : (
                  <Typography variant="body2" color="red">
                    No document available.
                  </Typography>
                )}
              </Typography>

              <TextField
                label="Enter OTP"
                variant="outlined"
                fullWidth
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                error={!!otpError}
                helperText={otpError}
                sx={{ mt: 2 }}
              />
              <Box display="flex" justifyContent="center" mt={2}>
                <Button variant="contained" onClick={verifyOtp}>
                  {"Verify OTP"}
                </Button>
              </Box>
            </Box>
          </Modal>

 <Modal open={submissionModalOpen} onClose={() => setSubmissionModalOpen(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
            width: { xs: '90%', sm: '50%', md: '40%', lg: '30%' },
            p: 4,
            textAlign: 'center',
          }}
        >
          <Box display="flex" alignItems="center" justifyContent="center" mb={2}>
            <CheckCircleIcon sx={{ color: 'green', fontSize: '40px', marginRight:2 }} />
            <Typography variant="h6">The proposal has been submitted successfully.</Typography>
          </Box>
          <Box display="flex" alignItems="center" justifyContent="center">
            <Typography>Please wait while we complete the final stage...</Typography>
            <CircularProgress size={24} sx={{ marginRight:2 }} />
          </Box>
        </Box>
      </Modal>
      </>
            )}
    </>
  );
};

export default ProductSummary;
