import { Button, Grid } from "@mui/material";
import { Form, Formik } from "formik";
import moment from "moment";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import { useEffect } from "react";
import {
  useGetProposalDetailsQuery,
  useGetRelationMasterQuery,
  useUpdateProposalFormData,
} from "../../../../services/Proposal.service";
import {
  allowOnlyAlphabets,
  allowOnlyNumbers,
  toCapitalize,
} from "../../../../utils/inputUtils";
import DateComp from "../InputComponents/DateComp";
import Select from "../InputComponents/Select";
import TextInput from "../InputComponents/TextInput";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { setProposalData } from "../../../../modules/proposal.slice";
import HeartLoader from "../../../../components/Loader/Loader";
import { useNavigate } from "react-router-dom";
import InputSideEffect from "../InputComponents/InputSideEffect";
const NomineeForm = () => {
  const { activeProposal, proposalData } = useTypedSelector(
    (state) => state.proposal
  );
  const { isCKYC, isPortability } = useGetProposalDetailsQuery();
  const { relations } = useGetRelationMasterQuery();
  const { saveForm, isSaved } = useUpdateProposalFormData(() => {
    if (!isPortability) {
      navigate({
        pathname: "/proposal-summary",
        search: window.location.search,
      });
    }
  });
  const navigate = useNavigate();
  const self_dob = proposalData?.proposer_details?.dob;
  console.log({ isPortability });
  // useEffect(() => {
  //   if (isSaved && !isPortability) {
  //   } else {
  //   }
  // }, [isSaved, isPortability]);

  const dispatch = useDispatch();
  if (activeProposal !== (isCKYC ? 4 : 3)) return null;

  return (
    <>
      <Formik
        initialValues={
          proposalData.nominee_details
            ? proposalData.nominee_details
            : {
                nominee_name: "",
                nominee_relation: "",
                nominee_dob: "",
                nominee_contact: "",
              }
        }
        validationSchema={yup.object().shape({
          nominee_name: yup.string().required("Nominee name is required"),
          nominee_relation: yup
            .string()
            .required("Nominee relation is required"),
          nominee_relation_data: yup.string(),
          nominee_dob: yup
            .string()
            .required("Nominee dob is required")
            .test(
              "nominee_dob",
              "Parents age must be 18 years older",
              function (val) {
                if (
                  self_dob &&
                  (this.parent.nominee_relation_data?.toLowerCase() ===
                    "father" ||
                    this.parent.nominee_relation_data?.toLowerCase() ===
                      "mother")
                ) {
                  console.log();
                  return (
                    moment(self_dob, "YYYY-MM-DD").diff(
                      moment(val, "YYYY-MM-DD"),
                      "years"
                    ) >= 18
                  );
                }
                return true;
              }
            )
            .test(
              "nominee_dob",
              "Children age must be 18 years younger",
              function (val) {
                if (
                  self_dob &&
                  (this.parent.nominee_relation_data?.toLowerCase() === "son" ||
                    this.parent.nominee_relation_data?.toLowerCase() ===
                      "daughter")
                ) {
                  console.log();
                  return (
                    moment(val, "YYYY-MM-DD").diff(
                      moment(self_dob, "YYYY-MM-DD"),
                      "years"
                    ) >= 18
                  );
                }
                return true;
              }
            )
            .test(
              "future-date-not-allowed",
              "Future Date is not allowed",
              (val) => {
                return moment(val, "YYYY-MM-DD").isBefore(moment());
              }
            ),
          nominee_contact: yup.string().required("Nominee contact is required"),

          appointee_name: yup
            .string()
            .nullable()
            .when("nominee_dob", {
              is: (val: string) =>
                moment().diff(moment(val, "YYYY-MM-DD"), "year") < 18,
              then: yup.string().required("This field is required."),
            }),
          appointee_relation: yup
            .string()
            .nullable()
            .when("nominee_dob", {
              is: (val: string) =>
                moment().diff(moment(val, "YYYY-MM-DD"), "year") < 18,
              then: yup.string().required("This field is required."),
            }),
          appointee_dob: yup
            .string()
            .nullable()
            .when("nominee_dob", {
              is: (val: string) =>
                moment().diff(moment(val, "YYYY-MM-DD"), "year") < 18,
              then: yup
                .string()
                .required("This field is required.")
                .test(
                  "future-date-not-allowed",
                  "Age should be greater than 18",
                  (val) => {
                    return (
                      moment().diff(moment(val, "YYYY-MM-DD"), "year") >= 18
                    );
                  }
                ),
            }),
        })}
        onSubmit={async (values) => {
          saveForm({
            nominee_details: values,
            from_screen: "nominee",
          });
          dispatch(
            setProposalData({
              nominee_details: values,
            })
          );
        }}
      >
        {({ submitForm, values, setFieldValue, errors }) => (
          <Form placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <>
                  <TextInput
                    label="Nominee Name"
                    name="nominee_name"
                    onInput={(e) => {
                      allowOnlyAlphabets(e);
                      toCapitalize(e);
                    }}
                  />
                  {console.log(errors)}
                </>
              </Grid>

              <Grid item xs={12} md={6}>
                <InputSideEffect
                  dependencies={[values.nominee_relation]}
                  effect={() => {
                    setFieldValue(
                      "nominee_relation_data",
                      relations?.find(
                        (item) => item.OPTION_KEY === values.nominee_relation
                      )?.OPTION_VAL || ""
                    );
                  }}
                >
                  <Select
                    label="Nominee's Relation to Proposer"
                    name="nominee_relation"
                    options={relations || []}
                  />
                </InputSideEffect>
              </Grid>
              <Grid item xs={12} md={6}>
                <DateComp
                  label="Nominee Dob"
                  name="nominee_dob"
                  minDate={moment().subtract(60, "years")}
                  maxDate={moment()}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextInput
                  label="Nominee Contact"
                  name="nominee_contact"
                  onInput={allowOnlyNumbers}
                  maxLength={10}
                />
              </Grid>
              {moment().diff(moment(values.nominee_dob, "YYYY-MM-DD"), "year") <
                18 && (
                <>
                  <Grid item xs={12} md={6}>
                    <TextInput
                      label="Appointee Name"
                      name="appointee_name"
                      onInput={(e) => {
                        allowOnlyAlphabets(e);
                        toCapitalize(e);
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <InputSideEffect
                      dependencies={[values.nominee_relation]}
                      effect={() => {
                        setFieldValue(
                          "appointee_relation_data",
                          relations?.find(
                            (item) =>
                              item.OPTION_KEY === values.nominee_relation
                          )?.OPTION_VAL || ""
                        );
                      }}
                    >
                      <Select
                        label="Appointee's Relation to Nominee"
                        name="appointee_relation"
                        options={relations || []}
                      />
                    </InputSideEffect>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <DateComp
                      label="Appointee Dob"
                      name="appointee_dob"
                      minDate={moment().subtract(60, "years")}
                      maxDate={moment().subtract(18, "years")}
                    />
                  </Grid>
                </>
              )}
              <Grid
                item
                xs={12}
                justifyContent="center"
                display={"flex"}
                my={1}
              >
                <Button
                  size="large"
                  variant="contained"
                  onClick={() => {
                    submitForm();
                  }}
                >
                  {isPortability
                    ? "Proceed to Portability"
                    : "Proceed to Review & Pay"}
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default NomineeForm;
