import { Autocomplete, Box, InputLabel, TextField } from "@mui/material";
import { useField, useFormikContext } from "formik";
import React from "react";

export interface SelectOptions {
  OPTION_KEY: string;
  OPTION_VAL: string;
}

interface TextInputProps {
  label: string;
  name: string;
  error?: boolean;
  helperText?: string;
  options: SelectOptions[];
  readOnly?: boolean;
  isMultiple?: boolean;
  required?: boolean;
  placeholder?: string; // Add placeholder prop
}

const Select: React.FC<TextInputProps> = ({
  name,
  label,
  required = false,
  placeholder,
  ...props
}) => {
  const [field, meta] = useField(name);
  const { setFieldValue, setFieldTouched, submitCount } = useFormikContext();

  const config = {
    ...field,
    ...props,
    getOptionLabel: (option: SelectOptions) => option.OPTION_VAL,
    isOptionEqualToValue: (option: SelectOptions, value: SelectOptions) =>
      option.OPTION_KEY === value.OPTION_KEY,
  };

  // Adjusting value assignment
  if (!props.isMultiple) {
    config.value = config.value
      ? props.options.find((item) => item.OPTION_KEY === config.value) || null
      : null;
  } else if (props.isMultiple) {
    config.value = config.value
      ? props.options.filter((item) => Array.isArray(config.value) && config.value.includes(item.OPTION_KEY))
      : [];
  }

  if (meta.error && (meta.touched || submitCount)) {
    config.error = true;
    config.helperText = meta.error;
  }

  return (
    <Autocomplete
      {...config}
      disableClearable
      size="small"
      fullWidth
      multiple={props.isMultiple}
      onChange={(event: any, value) => {
        if (Array.isArray(value)) {
          // Multiple select case
          setFieldValue(
            name,
            value ? value.map((item: SelectOptions) => item.OPTION_KEY) : []
          );
        } else if (value && 'OPTION_KEY' in value) {
          // Single select case
          setFieldValue(name, value ? value.OPTION_KEY : "");
        }
      }}
      onBlur={() => {
        setFieldTouched(name, true);
      }}
      renderInput={(params) => (
        <Box>
          <InputLabel
            sx={{
              fontSize: "15px",
              mb: 1,
              textOverflow: "unset",
              whiteSpace: "pre-line",
            }}
          >
            {label}
            {required && <span style={{ color: "red", fontWeight: "bold" }}> *</span>}
          </InputLabel>
          <TextField
            sx={{
              "& input": {
                color: "#2D2C5F",
                fontSize: "16px",
                pt: "12px",
              },
              "& fieldset": {
                height: "50px",
              },
            }}
            placeholder={placeholder} // Adding placeholder here
            error={config.error}
            helperText={config.helperText}
            {...params}
            inputProps={{
              ...params.inputProps,
              readOnly: props.readOnly,
            }}
          />
        </Box>
      )}
    />
  );
};

export default React.memo(Select);
